import React from 'react'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import {PaginationWrapper} from './Pagination.styles'

const Pagination = ({pageCount, initialPage, ...rest}) => (
  <PaginationWrapper>
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      initialPage={initialPage}
      disableInitialCallback
      breakLabel="..."
      breakClassName="pagination__break"
      containerClassName="pagination"
      pageClassName="pagination__page"
      pageLinkClassName="pagination__link"
      activeClassName="pagination__page--active"
      previousLinkClassName="pagination__button pagination__button--prev"
      nextLinkClassName="pagination__button pagination__button--next"
      previousLabel={initialPage !== 0 && <></>}
      nextLabel={pageCount !== initialPage + 1 && <></>}
      {...rest}
    />
  </PaginationWrapper>
)

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  initialPage: PropTypes.number.isRequired,
}

export default Pagination
