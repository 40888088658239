import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import moment from 'moment'
import {navigate} from 'gatsby'
import {
  Wrapper,
  Title,
  Subtitle,
  BlogBlockWrapper,
  BlogBlockWrapperOuter,
  AllBlogs,
  TitleWrapper,
} from './BlogBlockSection.styles'
import ContentContainer from '../../components/ContentContainer'
import BlogBlock from '../../components/BlogBlock'
import Categories from '../../components/Categories'
import ShowMob from '../../components/ShowMob'
import Pagination from '../../components/Pagination'
import routes from '../../config/routes'

const BlogBlockSection = ({
  title,
  subtitle,
  data,
  dataSingle,
  noAllBlogs,
  notSliced,
  showCategories,
  categoryData,
  minHeight,
  location,
  pageContext,
  ...rest
}) => {
  const {t, language} = useI18next()

  const slicedData = notSliced ? data : data?.slice(0, 4)

  const slicedDataSingle = dataSingle?.slice(0, 4)

  const blogPaths = []
  const blogsPaths = ['/blogs/', '/blogs', `/${language}/blogs`, `/${language}/blogs/`]
  const isMatch = path => path === location.pathname
  data?.map(item => blogPaths.push(`/blogs/${item.node.page_url}`))
  if (pageContext) {
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= pageContext.numberOfPages; i++) {
      blogPaths.push(`/blogs/${i}`)
      blogPaths.push(`/blogs/${i}/`)
      blogPaths.push(`/en/blogs/${i}`)
      blogPaths.push(`/en/blogs/${i}/`)
    }
  }
  data?.map(item => blogPaths.push(`/en/blogs/${item.node.page_url}`))
  const isMatchBlogPaths = () => blogPaths.some(isMatch)
  const isMatchBlogsPaths = () => blogsPaths.some(isMatch)

  return (
    <Wrapper {...rest} minHeight={minHeight} id="blog">
      <ContentContainer>
        <TitleWrapper>
          <Title content={title} size="titleXs" uppercase mb={10} />
          <Subtitle content={subtitle} size="md" />
        </TitleWrapper>
        {showCategories && (
          <ShowMob {...rest}>
            <Categories categoryData={categoryData} />
          </ShowMob>
        )}
        <BlogBlockWrapperOuter>
          <BlogBlockWrapper>
            {slicedData &&
              slicedData.map(item => (
                <BlogBlock
                  key={item.node.page_url}
                  pageUrl={item.node.page_url}
                  date={item.node.date[language]}
                  title={item.node.title[language]}
                  description_short={item.node.description_short[language]}
                />
              ))}
            {slicedDataSingle &&
              slicedDataSingle.map(item => (
                <BlogBlock
                  key={item.page_url}
                  pageUrl={item.page_url}
                  date={item.date[language]}
                  title={item.title[language]}
                  description_short={item.description_short[language]}
                />
              ))}
          </BlogBlockWrapper>
        </BlogBlockWrapperOuter>
        {(isMatchBlogPaths() || isMatchBlogsPaths()) && pageContext !== undefined ? (
          <Pagination
            onPageChange={({selected}) => navigate(routes.news({page: selected + 1}, {language}))}
            pageCount={pageContext.numberOfPages}
            initialPage={pageContext.pageNumber}
          />
        ) : null}
        {!noAllBlogs && (
          <AllBlogs to={language !== 'de' ? `/${language}/blogs` : `/blogs`}>
            {t('sections.blog.allBlogs')}
          </AllBlogs>
        )}
      </ContentContainer>
    </Wrapper>
  )
}

BlogBlockSection.propTypes = {}
BlogBlockSection.defaultProps = {}

export default BlogBlockSection
