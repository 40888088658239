import styled from 'styled-components'
import Text from 'components/Text'
import {Link} from 'gatsby'

export const Wrapper = styled(Link)(({theme}) => ({
  marginBottom: 30,
  maxWidth: 375,
  width: '100%',
  backgroundColor: theme.palette.common.white,
  padding: '27px 24px 50px 30px',
}))

export const Title = styled(Text)(({theme}) => ({
  marginBottom: 13,
}))

export const Date = styled(Text)(({theme}) => ({
  marginBottom: 11,
}))

export const Description = styled(Text)(({theme}) => ({
  marginBottom: 0,
  fontFamily: theme.fontFamilies.lato,
}))
