import React from 'react'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Wrapper, Title, Date, Description} from './BlogBlock.styles'

const BlogBlock = ({date, title, description_short, pageUrl, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  return (
    <Wrapper to={`/blogs/${pageUrl}`}>
      <Date size="def" content={date} />
      <Title size="titleXs" content={title} />
      <Description size="def" content={description_short} />
    </Wrapper>
  )
}

BlogBlock.propTypes = {}
BlogBlock.defaultProps = {}

export default BlogBlock
