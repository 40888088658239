import styled from 'styled-components'
// import ArrowRightSvg from 'images/icons/arrow-right.inline.svg'

export const PaginationWrapper = styled.div`
  .pagination {
    display: flex;
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: 700;
    height: 36px;

    .previous {
      display: none;
    }

    .next {
      display: none;
    }

    li {
      list-style: none;
    }

    &__break {
      display: flex;
      margin-right: 12px;
      align-items: center;
    }

    &__link {
      min-width: 36px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      outline: none;
    }

    &__page {
      transition-duration: 150ms;
      display: flex;
      flex-direction: column;
      background: ${({theme}) => theme.palette.common.white};
      border: 1px solid ${({theme}) => theme.palette.primary.main};
      margin-right: 12px;
      &:hover {
        background: ${({theme}) => theme.palette.primary.main};
        .pagination__link {
          color: ${({theme}) => theme.palette.common.white};
        }
      }
    }

    &__page--active {
      background: ${({theme}) => theme.palette.primary.main} !important;
      color: ${({theme}) => theme.palette.common.white};
    }

    &__button {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50px;

      &--prev > svg {
        transform: rotate(180deg);
      }
      &:hover {
        svg {
          fill: ${({theme}) => theme.palette.primary.main};
        }
      }
    }
    .disabled {
      display: none;
    }
  }
`

// export const ArrowIcon = styled(ArrowRightSvg)`
//   fill: ${({theme}) => theme.palette.primary.main};
//   transition-duration: 150ms;
// `
