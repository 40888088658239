import styled from 'styled-components'
import Text from 'components/Text'
import {Link} from 'gatsby'

export const Wrapper = styled.div(({theme, minHeight}) => ({
  paddingTop: 80,
  paddingBottom: 60,
  background: theme.palette.primary.bg,
  minHeight: minHeight ? 1715 : 'auto',

  [theme.breakpoints.down('laptopS')]: {
    minHeight: 'initial',
  },

  [theme.breakpoints.down('tablet')]: {
    padding: '60px 0',
  },
}))

export const BlogBlockWrapperOuter = styled.div(({theme}) => ({
  [theme.breakpoints.down('tablet')]: {
    margin: '0 -15px',
  },
}))

export const BlogBlockWrapper = styled.div(({theme}) => ({
  maxWidth: 770,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const TitleWrapper = styled.div(({theme}) => ({
  maxWidth: 700,
}))

export const Title = styled(Text)(({theme}) => ({
  marginBottom: 10,
}))

export const Subtitle = styled(Text)(({theme}) => ({
  marginBottom: 40,
}))

export const AllBlogs = styled(Link)(({theme}) => ({
  display: 'inline-block',
  marginTop: 30,
  textTransform: 'uppercase',
}))
